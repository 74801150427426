import React, { useState } from 'react'
import { create as ipfsHttpClient } from "ipfs-http-client";

function DemoTest() {
  const projectId = process.env.REACT_APP_INFURA_IPFS_PROJECT_ID;
  const projectSecret = process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET;
  const projectIdAndSecret = `${projectId}:${projectSecret}`;
  const ipfsURL = "https://braands.infura-ipfs.io/ipfs/";

  const [myFile, setMyFile] = useState("");
  const [myIcon, setMyIcon] = useState("");
  const [myFileIcon, setMyFileIcon] = useState("");
  const [title, setTitle] = useState("");

  const [myURL, setMyURL] = useState("");

  const client = ipfsHttpClient({
    host: "ipfs.infura.io",
    port: 5001,
    protocol: "https",
    headers: {
      authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
        "base64"
      )}`,
    },
  });

  const onSave = async (title, file, icon, fileicon) => {
    /* first, upload to IPFS */
    if (file) {
      const data = JSON.stringify({
        name: title,
        image: fileicon,
        myFile: file,
        myIcon: icon
      });
      try {
        const added = await client.add(data);
        const url = `${ipfsURL}${added.path}`;
        /* after file is uploaded to IPFS, return the URL to use it in the transaction */
        setMyURL(url);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  }

  const uploadFile = async (source) => {
    try {
      const added = await client.add(source, {
        progress: (prog) => {
          
        },
      });
      const url = `${ipfsURL}${added.path}`;
      return url;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  const submitFunction = async (e) => {
    e.preventDefault();
    const myFile1 = await uploadFile(myFile);
    const myIcon1 = await uploadFile(myIcon);
    const myFileIcon1 = await uploadFile(myFileIcon);
    // const myFile1 = "https://braands.infura-ipfs.io/ipfs/QmVQuj9SGZaE8KiWtXM21kLGCweqp2Syb6r4oHenq63WJN";
    // const myIcon1 = "https://braands.infura-ipfs.io/ipfs/QmUZqie6PEU9PaDiBVN2KRK7Bsy7XAfmVFm1WZYhM4XP18";

    await onSave(title, myFile1, myIcon1, myFileIcon1);
  }

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2 className='mb-0'>URL : <a target="_blank" href={myURL}>{myURL}</a></h2>
      <div className='d-flex justify-content-center'>
        <form className='mt-5 px-4 py-3' style={{background: '#eee'}} type="post" onSubmit={submitFunction}>
          <div className='mb-4'>
            <h3 className='mb-1'>Enter Title</h3>
            <input type="text" name='title' placeholder='Enter Title' onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className='mb-4'>
            <h3 className='mb-1'>Upload Logo</h3>
            <input type="file" name='myFile' onChange={(e) => setMyFile(e.target.files[0])} />
          </div>
          <div className='mb-4'>
            <h3 className='mb-1'>Upload Icon</h3>
            <input type="file" name='myIcon' onChange={(e) => setMyIcon(e.target.files[0])} />
          </div>
          <div className='mb-4'>
            <h3 className='mb-1'>Upload File Icon</h3>
            <input type="file" name='myIcon' onChange={(e) => setMyFileIcon(e.target.files[0])} />
          </div>
          <div className='mb-4'>
            <input type="submit" className='btn-main' />
          </div>

        </form>
      </div>

      {/* <button onClick={() => {
        onSave("senddy.io",
          "https://braands.infura-ipfs.io/ipfs/QmaQbxNApcCJGRBcMC68GNVYXdPJgsNR4J4LZgQPr8fj2Z",
          "https://braands.infura-ipfs.io/ipfs/QmZDmas31EGJhfKnsyg5aMmRCBVZkoNWcxk66bWFDwQoVP")
      }}>SAVE</button> */}
    </>
  )
}

export default DemoTest