import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { initEntityState, entityLoadingStarted, entityLoadingSucceeded, entityLoadingFailed } from '../utils';

export const defaultState = {
  userDetails: initEntityState(null),
  status: null
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case getType(actions.getUser.request):
      return { ...state, userDetails: entityLoadingStarted(state.userDetails, action.payload) };
    case getType(actions.getUser.success):
      return { ...state, userDetails: entityLoadingSucceeded(state.userDetails, action.payload) };
    case getType(actions.getUser.failure):
      return { ...state, userDetails: entityLoadingFailed(state.userDetails, action.payload) };
    
    case getType(actions.setUser.request):
      return { ...state, status: null};
    case getType(actions.setUser.success):
      return { ...state, status: action.payload};
    case getType(actions.setUser.failure):
      return { ...state, status: action.payload};
    default:
      return state;
  }
};

export default states;
