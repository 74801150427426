import React, { useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components';
import { APP_DATA } from '../../core/utils';
import { priceToBNB } from '../../utils';


const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .navbar.sticky.white{
    background: #fff !important;
    border-bottom: none;
    .navbar-logo-white{
      display: none;
    }
    .navbar-logo-pink{
      display: block;
    }
  }
  .btn-main.white{
    background: rgba(131,100,266, .2);
  }
  .text-dull{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .sharing-option{
    position: absolute;
    top: -5px;
    right: 0px;
    background: rgba(240, 240, 240, 1);
    border: 2px solid rgba(0, 0, 0, 0.02);
    padding: 3px;
    border-radius: 130px;
    cursor: pointer;
    .like-icon img{
        width: 25px;
        height: 25px;
    }
    .share-icon svg{
        width: 25px;
        height: 20px;
    }
  }
  .auction-counter{
    .count-box-outer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 6px;
        .count-box{
            display: flex;
            gap: 3px;
            div {
                width: 30px;
                height: 35px;
                background: black;
                color: white;
                display: flex;
                font-weight: bold;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
            }
        }
    }
  }
  .inputStyle{
    text-align: center;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 120px;
    font-weight: 600;
  }
`;

const imgaes = {
  "blockdot": "https://braands.infura-ipfs.io/ipfs/QmT15yjPkDtctG2o8MfDsoPL6FHYXunSC9VXSXqXVLUAYZ",
  "auto-speech": "https://braands.infura-ipfs.io/ipfs/QmXHmYisa5wGJFR48PnjTjKG1WCuBwxLHjxgLMzxC2rqx7",
  "block-control": "https://braands.infura-ipfs.io/ipfs/QmemxNR7YznaRm9zk2qxP97769DdaWyhmGSBtBJUz7knw6",
  "block-sense": "https://braands.infura-ipfs.io/ipfs/QmZ9usALcsttDCErtbZLiAS24fK5sDg5VKSRmkVGRi2vKW",
  "chatbot-banking": "https://braands.infura-ipfs.io/ipfs/QmVAyDD9QPbSCvPqUcWP1DFhKBSumzhe2sHXFdt17xA82Y",
  "kyc-blocks": "https://braands.infura-ipfs.io/ipfs/QmP6xkLNEMfoZceFB3XeGNWqZGCxrBkcVEXcmyTXF7aeXX",
  "nano-properties": "https://braands.infura-ipfs.io/ipfs/QmZioC4Gf7VremwX8x4dVFjhfhuULz2Tw3Y9HkH1SurA7H",
  "plugseal": "https://braands.infura-ipfs.io/ipfs/QmbTjPMGhPfFs2KBjsr12RpobjXfq3U68Pc4uFxdnSD2Fu",
}

function AuctionTop({ nftTitle }) {
  const [nft, setNft] = useState();

  useEffect(() => {
    let nft = {};
    nft.image = imgaes[nftTitle];
    
    switch (nftTitle) {
      case "block-control":
        nft.title = "Block Control";
        break;
      case "block-dot":
        nft.title = "Block Dot";
        break;
      case "block-sense":
        nft.title = "Block Sense";
        break;
      case "plugseal":
        nft.title = "Plugseal";
        break;
      case "auto-speech":
        nft.title = "Auto Speech";
        break;
      case "chatbot-banking":
        nft.title = "Chatbot Banking";
        break;
      case "blackdot":
        nft.title = "Blackdot";
        break;
      case "kyc-blocks":
        nft.title = "Kyc Blocks";
    }
    setNft(nft);
  }, []);

  return (
    <>
      <GlobalStyles />
      <section className="container-fluid auction-details" style={{ background: "#fff" }}>
        <div className="container">
          <div className="row mt-md-3 pt-md-4">
            <div className="col-md-4 p-0 p-md-3 text-center">
              <div className="domain-main h-100">
                <div className="domain-style-3">
                  <img src={nft?.image} alt="logo" />
                </div>
              </div>
            </div>
            <div className="col-md-8 p-0 p-md-3 border-left ps-md-3">
              <div className="item_info position-relative">
                <h3 className="mb-1 fw-600 text-dull">Domain & Logo</h3>
                <h2 className="mb-2 fw-800">{nft?.title}</h2>
                <h4 className="fw-500 text-dull d-flex gap-2 flex-wrap align-items-center">
                  Highest bid
                  <div className="pink-rounded-box">
                    1.45 {" " + APP_DATA.CURR_CURRENCY}
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AuctionTop