import React from "react";
import { createGlobalStyle } from "styled-components";
import "../../assets/new_light_styles.scss";
import Footer1 from "../components/footer1";
import MyCustomSlider from "../components/MyCustomSlider";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    border-bottom: none;
    background: #fff !important;
    .navbar-logo-white{
      display: none !important;
    }
    .navbar-logo-pink{
      display: block !important;
    }
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #fff;
      .navbar-logo-white{
        display: block !important;
      }
      .navbar-logo-pink{
        display: none !important;
      }
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const bg1 = {
  backgroundImage: "url('/img/background/header-bg.webp')",
};

const ThankYou = () => {

  return (
    <div>
      <GlobalStyles />
      <section className="container top-hero-header mt-1 pb-5">
        <div className="content-right d-flex top-header-card-container mt-5" style={bg1}>
          <MyCustomSlider />
        </div>
      </section>

      <div className="privacy-section">
        <div className="container ">
          <h1 className="second-title">Thank You For Purchase</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus. Iste quo alias totam vero iusto ut facere dignissimos! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus. Iste quo alias totam vero iusto ut facere dignissimos! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus. Iste quo alias totam vero iusto ut facere dignissimos! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus. Iste quo alias totam vero iusto ut facere dignissimos! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus. Iste quo alias totam vero iusto ut facere dignissimos! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus. Iste quo alias totam vero iusto ut facere dignissimos! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum voluptatibus voluptates unde eos, dolor neque nesciunt nemo pariatur laborum minus natus.
          </p>
          <div className="d-flex gap-4 justify-content-center align-items-center">
            <button className="btn-main">Download Invoice</button>
            <button className="btn-main" onClick={()=> { window.location.href = "/profile" }}>Back To Profile</button>
          </div>
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default ThankYou;
