import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { initEntityState, entityLoadingStarted, entityLoadingSucceeded, entityLoadingFailed } from '../utils';

export const defaultState = {
  domains: initEntityState(null),
  icons: initEntityState(null),
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    
    case getType(actions.getDomains.request):
      return { ...state, domains: entityLoadingStarted(state.domains) };
    case getType(actions.getDomains.success):
      return { ...state, domains: entityLoadingSucceeded(state.domains, action.payload) };
    case getType(actions.getDomains.failure):
      return { ...state, domains: entityLoadingFailed(state.domains) };

    case getType(actions.getLogos.request):
      return { ...state, icons: entityLoadingStarted(state.icons) };
    case getType(actions.getLogos.success):
      return { ...state, icons: entityLoadingSucceeded(state.icons, action.payload) };
    case getType(actions.getLogos.failure):
      return { ...state, icons: entityLoadingFailed(state.icons) };

    case getType(actions.setIcons):
      return { ...state };
    
    default:
      return state;
  }
};

export default states;
