import React, { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components';
import Cookies from 'js-cookie';

const GlobalStyles = createGlobalStyle`
.material-cookies-box {
  width: 350px;
  max-width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e7ecfe));
  background: -o-linear-gradient(top, #fff, #e7ecfe);
  background: linear-gradient(to bottom,#fff, #e7ecfe);
  display: block;
  -webkit-box-shadow: 0 10px 29px 0 rgba(126, 136, 172, 0.44);
  box-shadow: 0 10px 29px 0 rgba(126, 136, 172, 0.44);
  padding: 0 0 5px;
  border-radius: 10px;
  margin: 0;
  overflow: hidden;
  position: fixed;
  z-index: 2000;
  -webkit-transition: transform 0.5s ease-in;
  -o-transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  bottom: 25px;
  left: 25px;
  -webkit-transform: translateX(-400px);
    -ms-transform: translateX(-400px);
    transform: translateX(-400px);
}
.material-cookies-box.show-from-left.show, .material-cookies-box.show-from-right.show  {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.material-cookies-box .separator-wrapper {
  position:relative;
  overflow:hidden;
  height: 83px;
}
.material-cookies-box .separator-wrapper.separator-top svg {
  fill: #f20d6b;
  position: absolute;
  top: 0;
}
.cookie-wrapper {
  width: 60%;
  position: relative;
  display: block;
  margin: -80px auto 0;
}
.material-cookies-box svg{
  margin: 0 auto;
  width:100%;
  height:auto;
  display:block;
}
.material-cookies-box #web_cookie {
  position: absolute;
  top: 30%;
  width: 40%;
  left: 30%;
}
.material-cookies-box .cookies-content {
  text-align: center;
  color: #5e697d;
  width: 90%;
  margin: 0 auto;
}
.material-cookies-box .cookies-content h5 {
  color: #f20d6b;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-top:0;
}
.material-cookies-box .cookies-content p {
  margin-top: 10px;
  font-weight: 400;
  color: #607d9e;
  line-height: 1.3rem;
  font-size: 1rem;
}

.material-cookies-box .cookies-content p a {
  color: #f20d6b;
  text-decoration: none;
}

.btn-cookie {
  outline: none !important;
  height: 50px;
  line-height: 48px;
  padding: 0 40px;
  border-radius: 30px;
  margin: 20px auto;
  border: 1px solid #f20d6b;
  display: block;
  cursor: pointer;
  text-align: center;
  width: 300px;
  background-color: #f20d6b;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  max-width: 100%;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.btn-cookie.btn-transparent {
  border: 0;
  color: #f20d6b;
  background-color: #fff;
  border: 1px solid #f20d6b;
}

.btn-cookie:hover, .btn-cookie:focus {
  // border: 1px solid #f20d6b;
  -webkit-box-shadow: 0 10px 29px 0 rgba(126, 136, 172, 0.44);
  box-shadow: 0 10px 29px 0 rgba(126, 136, 172, 0.44);
  // background: linear-gradient(107.33deg, #F20D6B 0%, #FF75AE 100%);
  // color: #fff;
}
`;

const Cookies1 = () => {
  useEffect(()=>{
    const box = document.getElementById("my-cookies-box");
    if(Cookies.get("_cookies")){

    } else {
      setTimeout(() => {
        box.classList.add("show");
      }, 1500);
    }
  },[]);

  const acceptCookies = () => {
    const box = document.getElementById("my-cookies-box");
    Cookies.set("_cookies", "DONE");
    box.classList.remove("show");
  }
  const declineCookies = () => {
    const box = document.getElementById("my-cookies-box");
    box.classList.remove("show");
  }

  return (
    <div>
      <GlobalStyles />
      <div id="my-cookies-box" className="material-cookies-box bottom-left show-from-left">
        <div className="separator-wrapper separator-top">
          <svg className="curved-divider curved-bottom-left" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="590 165.4 1599 265.9">
            <g id="Design-1">
              <g id="landing-page-1" transform="translate(-1.000000, -660.000000)">
                <g id="Group-19" transform="translate(1.000000, 660.000000)">
                  <path id="Rectangle_3_" className="st0" d="M2189.1,165.4l0,225.9c-202.1-53.3-468.6-53.3-799.6,0c-330.9,53.3-597.5,53.3-799.6,0V165.4L2189.1,165.4z"></path>
                </g>
              </g>
            </g>
          </svg>

        </div>

        <div className="cookie-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 288 288">
            <linearGradient id="PSgrad_0" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
              <stop offset="0%" stop-color="#d4e1f4" stop-opacity="0.6"></stop>
              <stop offset="100%" stop-opacity="1" stop-color="#e6ecff"></stop>
            </linearGradient>
            <path fill="url(#PSgrad_0)">
              <animate repeatCount="indefinite" attributeName="d" dur="5s" values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z;M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8C48.9,198.6,57.8,191,51,171.3z;M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z"></animate>
            </path>
          </svg>
          <svg
            id="web_cookie"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
          >
            <path
              fill="#C8D5F4"
              d="M498.985 288.17a80.81 80.81 0 01-11.757.862c-36.782 0-67.912-24.061-78.59-57.295-1.826-5.681-6.465-10.055-12.327-11.171-61.084-11.63-107.277-65.257-107.277-129.727 0-27.077 8.174-52.228 22.155-73.177 3.324-4.981.55-11.615-5.324-12.778A256.452 256.452 0 00256.002 0C114.617.001.002 114.615.002 256c0 141.384 114.615 256 256 256 127.423 0 232.787-92.752 252.604-214.608.899-5.529-4.08-10.035-9.621-9.222z"
            ></path>
            <g fill="#ABB9DB">
              <circle cx="140.392" cy="173.42" r="33.03"></circle>
              <circle cx="156.902" cy="338.58" r="24.774"></circle>
            </g>
            <path
              fill="#A0ACCA"
              d="M404.655 338.581a8.257 8.257 0 01-7.395-4.565l-8.258-16.516a8.258 8.258 0 013.694-11.081 8.27 8.27 0 0111.081 3.694l8.258 16.516a8.258 8.258 0 01-7.38 11.952z"
            ></path>
            <g fill="#A0ACCA">
              <path d="M313.816 289.032a8.258 8.258 0 01-3.701-15.646l16.516-8.258c4.081-2.024 9.032-.387 11.081 3.694a8.258 8.258 0 01-3.694 11.081l-16.516 8.258a8.267 8.267 0 01-3.686.871zM90.849 272.516a8.257 8.257 0 01-7.395-4.565l-8.258-16.516a8.258 8.258 0 013.694-11.081c4.073-2.024 9.032-.387 11.081 3.694l8.258 16.516a8.258 8.258 0 01-7.38 11.952zM198.204 421.161a8.258 8.258 0 01-3.701-15.646l16.516-8.258a8.258 8.258 0 117.387 14.775l-16.516 8.258a8.267 8.267 0 01-3.686.871zM214.712 99.097a8.231 8.231 0 01-5.839-2.419l-8.258-8.258a8.254 8.254 0 010-11.677 8.254 8.254 0 0111.677 0L220.55 85a8.254 8.254 0 010 11.677 8.224 8.224 0 01-5.838 2.42z"></path>
            </g>
            <g fill="#A0ACCA">
              <path d="M198.196 289.032a8.231 8.231 0 01-5.839-2.419l-8.258-8.258a8.254 8.254 0 010-11.677 8.254 8.254 0 0111.677 0l8.258 8.258a8.254 8.254 0 010 11.677 8.229 8.229 0 01-5.838 2.419zM289.034 462.452a8.253 8.253 0 01-8.258-8.258v-8.258a8.253 8.253 0 018.258-8.258 8.254 8.254 0 018.258 8.258v8.258a8.253 8.253 0 01-8.258 8.258zM239.486 148.646a8.231 8.231 0 01-5.839-2.419 8.254 8.254 0 010-11.677l8.258-8.258a8.254 8.254 0 0111.677 0 8.254 8.254 0 010 11.677l-8.258 8.258a8.231 8.231 0 01-5.838 2.419z"></path>
            </g>
            <g fill="#C8D5F4">
              <path d="M362.343 66.935l-2.369 14.215c-1.947 11.683 4.648 23.111 15.738 27.27l20.975 7.866c13.855 5.195 29.145-2.833 32.733-17.188l7.477-29.906c4.66-18.639-12.751-35.259-31.153-29.738l-26.083 7.825a24.77 24.77 0 00-17.318 19.656zM445.937 156.904l-9.973 4.987c-7.822 3.911-11.239 13.241-7.794 21.278l8.024 18.722c4.858 11.335 19.974 13.541 27.869 4.067l12.908-15.49c6.052-7.263 4.785-18.114-2.778-23.786l-10.958-8.219a16.52 16.52 0 00-17.298-1.559z"></path>
              <circle cx="495.482" cy="107.36" r="16.516"></circle>
            </g>
            <g fill="#B9C7E8">
              <path d="M286.97 481.032c-141.385 0-256-114.615-256-256 0-62.808 22.623-120.333 60.158-164.874C35.404 107.119.002 177.423.002 256c0 141.384 114.615 256 256 256 78.626 0 148.85-35.315 195.763-90.992-44.492 37.472-101.964 60.024-164.795 60.024zM400.361 97.119c-13.852-5.195-22.09-19.468-19.658-34.06l2.824-16.941-3.865 1.16a24.778 24.778 0 00-17.319 19.657l-2.369 14.215c-1.947 11.683 4.648 23.111 15.738 27.27l20.975 7.866c11.124 4.171 23.165-.172 29.34-9.48l-25.666-9.687zM504.776 114.581c-9.121 0-16.516-7.395-16.516-16.516 0-2.14.411-4.198 1.152-6.074-6.123 2.417-10.442 8.383-10.442 15.365 0 9.121 7.395 16.516 16.516 16.516 6.982 0 12.947-4.319 15.364-10.442a16.558 16.558 0 01-6.074 1.151zM453.814 191.249l-9.088-21.204c-1.707-3.982-1.849-8.223-.815-12.129l-7.948 3.974c-7.822 3.911-11.239 13.241-7.794 21.279l8.024 18.722c4.858 11.335 19.974 13.541 27.868 4.068l3.208-3.85c-5.675-1.16-10.864-4.815-13.455-10.86z"></path>
            </g>
            <g fill="#A0ACCA">
              <path d="M157.938 188.904c-18.243 0-33.032-14.789-33.032-33.032 0-4.569.929-8.92 2.606-12.878-11.845 5.02-20.155 16.751-20.155 30.426 0 18.243 14.789 33.032 33.032 33.032 13.674 0 25.406-8.31 30.425-20.155a32.92 32.92 0 01-12.876 2.607zM169.809 350.452c-13.682 0-24.774-11.092-24.774-24.774 0-3.494.74-6.81 2.046-9.825-8.789 3.806-14.949 12.54-14.949 22.728 0 13.682 11.092 24.774 24.774 24.774 10.188 0 18.922-6.16 22.728-14.949a24.664 24.664 0 01-9.825 2.046z"></path>
            </g>
            <circle cx="313.812" cy="363.36" r="33.03" fill="#ABB9DB"></circle>
            <path
              fill="#A0ACCA"
              d="M331.357 378.839c-18.243 0-33.032-14.789-33.032-33.032 0-4.569.929-8.92 2.606-12.878-11.845 5.02-20.155 16.751-20.155 30.426 0 18.243 14.789 33.032 33.032 33.032 13.674 0 25.406-8.31 30.425-20.155a32.916 32.916 0 01-12.876 2.607z"
            ></path>
            <circle cx="247.402" cy="214.71" r="16.516" fill="#ABB9DB"></circle>
            <path
              fill="#A0ACCA"
              d="M256.174 222.452c-9.122 0-16.516-7.395-16.516-16.516 0-2.285.465-4.46 1.303-6.439-5.922 2.51-10.077 8.376-10.077 15.213 0 9.121 7.394 16.516 16.516 16.516 6.837 0 12.703-4.155 15.213-10.077a16.475 16.475 0 01-6.439 1.303z"
            ></path>
          </svg>
        </div>

        <div className="cookies-content">
          <h5>Our website is using Cookies</h5>
          <p>
            We use cookies to improve your experience and deliver personalized content. By using our site, you agree to our <a href="/">Cookie Policy</a>.
          </p>

          <button id="acceptCookies" onClick={acceptCookies} className="btn-cookie">Accept Cookies</button>
          <button id="editPreferences" onClick={declineCookies} className="btn-cookie btn-transparent">Decline Cookies</button>

        </div>

      </div>
    </div>
  )
}

export default Cookies1