import { Axios, Canceler } from "../../../core/axios";
import { APP_DATA } from "../../../core/utils";
import * as actions from "../../actions";
import Cookies from 'js-cookie'

const token = Cookies.get("Token");
const customeHeaders = {
    Authorization: "Token " + token,
}

export const loginUser = (account) => async (dispatch)=>{
    dispatch(actions.getUser.request(Canceler.cancel));
    Cookies.remove("Token");

    try{
        let bodyData = new FormData();
        bodyData.append('wallet_address', account);

        const res = await Axios.post(APP_DATA.API_URL+"/login", bodyData);
        Cookies.set("Token", res.data.token);
    } catch(err){
        Cookies.remove("Token");
        dispatch(actions.getUser.failure({message: "You are not register with us!!!"}));
    }
}

export const getUserDetails = () => async (dispatch)=>{
    dispatch(actions.getUser.request(Canceler.cancel));
    
    const customeHeaders = {
        Authorization: "Token " + Cookies.get("Token"),
    }

    try{
        const res = await Axios.get(APP_DATA.API_URL+"/profile", {headers: customeHeaders});
        dispatch(actions.getUser.success(res.data.data));
    } catch(err){
        dispatch(actions.getUser.failure({message: "Not Registered!!!"}));
    }
}

export const registerUser = (data) => async (dispatch)=>{
    dispatch(actions.setUser.request(Canceler.cancel));

    try{
        await Axios.post(APP_DATA.API_URL+"/register",data);
        dispatch(actions.setUser.success({messgae: 'User Created Successfully.'}));
    } catch(err){
        dispatch(actions.setUser.failure({messgae: 'User Created Successfully Error!!!', err}));
    }
}

export const updateUser = (data) => async (dispatch)=>{
    dispatch(actions.setUser.request(Canceler.cancel));

    try{
        await Axios.patch(APP_DATA.API_URL+"/profile", data, {headers: customeHeaders});
        dispatch(actions.setUser.success({message: "User Updated Successfully."}));
    } catch(err){
        dispatch(actions.setUser.failure(err));
    }
}