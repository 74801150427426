import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
header{
  display: none !important;
}
`;

function AdminDashboard() {
  const [username, setUsername] = useState("");
  
  useEffect(() => {
    if (Cookies.get("adminusername")) {
      setUsername(Cookies.get("adminusername"))
    } else {
      window.location.href = "/admin";
    }
  }, []);

  const logout = () => {
    Cookies.remove("adminusername");
    window.location.href = "/admin";
  }

  return (
    <>
      <GlobalStyles />
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-md-6'>
            <h3>Admin Dashboard</h3>
          </div>
          <div className='col-md-6 text-end'>
            <button onClick={logout} className='btn-main'>Logout</button>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Username</th>
              <th>Wallet Address</th>
              <th>Amount</th>
              <th>Time</th>
              <th className='text-center'>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Aditya1</td>
              <td>0x033...7680</td>
              <td>0.87 BNB</td>
              <td>17-01-2023</td>
              <td className='text-center'>
                <button className='btn-main py-1 px-4'>Approve</button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default AdminDashboard