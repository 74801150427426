import React from "react";

const EmptyNftCard = ({className = "col-lg-3 col-md-4 col-sm-6 col-12 mt-2 mt-md-4" }) => {
  return (
    <div className={className}>
      <div
        className="item-card cursor-pointer"
        style={{
          border: "2px solid #F20D6B",
          height: "424px",
          maxWidth: "calc(100% + 300px)"
        }}
      >
        <div className="item-main text-center">
          <div className={"siteLoader content-emptycard active"}></div>
        </div>
      </div>
    </div>
  );
};

export default EmptyNftCard;
