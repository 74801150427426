import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
header{
  display: none !important;
}
.main-container{
  background-color: #f1f1f1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

label {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 24px;
}

input[type="submit"] {
  width: 100%;
  background-color: #f20d6b;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 24px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #d31b67;
}`;

function AdminLogin() {
  const [input, setInput] = useState({
    email: 'info@braands.io',
    otp: ''
  })
  const [sendOtp, setSendOtp] = useState(false);

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    toast.success('OTP has successfully sent to your email');
    setSendOtp(true);
  }

  const onOTPSubmit = (e) => {
    e.preventDefault();
    if (input.otp === "1234") {
      Cookies.set("adminusername", input.email);
      window.location.href = "/admin/dashboard";
    } else {
      toast.error('Invalid OTP');
    }
  }

  return (
    <div className='main-container'>
      <GlobalStyles />
      <div class="login-container">
        <a href='/'>Back to home</a>
        <form onSubmit={onSubmit} className="mt-4">
          <hr /><h2>Admin Login</h2><hr />
          <label for="email">Email:</label>
          <input type="text" id="email" value={input.email} disabled placeholder='Email' name="email" onChange={onChange} />
          <input type="submit" value="Send OTP" hidden={sendOtp ? true : false} />
        </form>
        {sendOtp &&
          <form onSubmit={onOTPSubmit} className="mt-4">
            <label for="otp">OTP:</label>
            <input type="text" id="otp" value={input.otp} placeholder='Enter OTP' name="otp" onChange={onChange} />
            <input type="submit" value="Sabmit OTP" />
          </form>
        }
      </div>
    </div>
  )
}

export default AdminLogin