import { Axios, Canceler } from '../../../core/axios';
import { APP_DATA } from '../../../core/utils';
import * as actions from '../../actions';

export const searchLogoIcons = (searchQuery, filter = {}, first = true) => async (dispatch, getState) => {
  dispatch(actions.getLogos.request(Canceler.cancel));

  try {
    let query = `?search=${searchQuery}`;
    if (filter.color) {
      query += `&color=${filter.color}`;
    }
    if (filter.page) {
      query += `&page=${filter.page}`;
    }
    if (filter.style) {
      query += `&style=${filter.style}`;
    }
    const response2 = await Axios.get(`${APP_DATA.API_URL}/searchicons${query}`);
    let finalData = [];
    if (first) {
      finalData = response2.data;
    } else {
      let t = getState().logo.icons.data;
      let result1 = t.results;
      let result2 = response2.data.results;
      t.results = result1.concat(result2);
      finalData = t;
    }
    dispatch(actions.getLogos.success(finalData));
  } catch (error) {
    dispatch(actions.getLogos.failure(error));
  }
};

export const searchDomains = (searchQuery, industry) => async (dispatch, getState) => {
  dispatch(actions.getDomains.request(Canceler.cancel));

  try {
    const response = await Axios.get(`${APP_DATA.API_URL}/getdomain?search=${searchQuery}&industry=${industry}`);
    let finalData = [];
    let finalData1 = [];
    response?.data?.search_results.map(async (ele, index) => {
      let obj = ele;
      let query = "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=USD";
      if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
        query = "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=USD";
      }
      const binancecoin = await Axios.get(query);
      let apiConPrice;
      if (localStorage.getItem("chainId") === APP_DATA.chainId1) {
        apiConPrice = parseFloat(binancecoin.data.binancecoin.usd);
      } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
        apiConPrice = parseFloat(binancecoin.data["matic-network"].usd);
      }

      obj.total_bnb_price = obj.registration_price ? parseFloat(obj.registration_price) / apiConPrice : 0;
      finalData.push(obj);

      if (response.data.search_results.length === index + 1) {
        response?.data?.suggeted_domain?.map(async (ele1, index1) => {
          let obj1 = ele1;
          const binancecoin = await Axios.get(query);

          obj1.total_bnb_price = obj1.registration_price ? parseFloat(obj1.registration_price) / apiConPrice : 0;
          finalData1.push(obj1);

          if (response.data.suggeted_domain.length === index1 + 1) {
            dispatch(actions.getDomains.success({ main: finalData, suggested: finalData1 }));
          }
        });
      }
    })
    dispatch(actions.getDomains.success(response.data.results));
  } catch (error) {
    dispatch(actions.getDomains.failure([]));
  }

};