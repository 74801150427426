import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { navigate } from "@reach/router";
import { BiRightArrowCircle } from "react-icons/bi";
import {
  BsPinterest,
  BsTwitter,
  BsInstagram,
  BsLinkedin,
} from "react-icons/bs";
import { AiFillFacebook } from "react-icons/ai";
import { FaBehanceSquare, FaTelegramPlane } from "react-icons/fa";
import { SiMedium, SiBinance } from "react-icons/si";
import axios from "axios";
import { APP_DATA } from "../../core/utils";
import { toast } from "react-toastify";

const GlobalStyles = createGlobalStyle`
.footer-pink{
  position: relative;
  padding-top: 100px;
}
video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 1 !important;
  transform: scale(1) !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.overlay-footer{
  background-image: url("/img/background/home-footer-overlay-bg.png");
  background-size: cover;
  border-radius: 35px;
  padding: 70px 30px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
  top: -250px;
  .search-outer{
    position: relative;
    input{
      width: 100%;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(32px);
      outline: none;
      border: none;
      border: 1px solid #F20D6B;
      border-radius: 400px;
      padding: 10px 20px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 500;
      font-size: 22px;
    }
    button {
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 6px 20px;
      background: #F20D6B;
      border-radius: 200px;
      outline: none;
      border: none;
      color: #fff;
      font-size: 22px;
    }
  }
}
.overlay-footer-customer{
  background-size: cover;
  border-radius: 35px;
  padding: 70px 30px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
  top: -250px;
  max-height: 400px
}
@media only screen and (min-width:321px) and (max-width:768px) {
  .heading-overlay{
    font-size: 38px;
  }
}

@media only screen and (min-width:300px) and (max-width:400px) {
  .overlay-footer{
    .search-outer{
      input{
        font-size: 16px;
      }
      button {
        font-size: 16px;
      }
  }
  }
`;

const DefaultOverlaly = ({ search, setSearch }) => {
  return (
    <>
      <h1 className="heading-overlay">
        Build your ideal <br />
        brand today.
      </h1>
      <div className="search-outer mt-4">
        <input
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search here"
        />
        <button onClick={() => navigate("/result/" + search)}>
          Next <BiRightArrowCircle />
        </button>
      </div>
    </>
  );
};

const Footer1 = ({ overlay = null, Childeren, bg }) => {
  const [search, setSearch] = useState("");
  const [subsEmail, setSubsEmail] = useState("");
  const [urlRefer, setUrlRefer] = useState(null);

  const subscribeNow = () => {
    const formData = new FormData();
    formData.append("email", subsEmail);

    if(subsEmail){
    axios
      .post(APP_DATA.API_URL + "/newsletter", formData)
      .then((res) => {
        toast.success("Your request has been successfully submitted.");
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Some Error Occurred!!!");
      });
    } else {
      toast.error("Email ID is required !!!");
    }
  };

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.refer) {
      setUrlRefer(params?.refer)
    } else {
      setUrlRefer(null)
    }
  }, []);

  const getURL = (url) => {
    return urlRefer ? url + window.location.search : url;
  }

  return (
    <>
      <GlobalStyles />

      <footer
        className={`footer-pink px-3 pb-4 ${!overlay ? "pt-4 mt-0" : ""}`}
      >
        {overlay && (
          <div
            className={`container  ${bg ? "overlay-footer-customer" : "overlay-footer"
              }`}
          >
            {Childeren ? (
              <Childeren />
            ) : (
              <DefaultOverlaly search={search} setSearch={setSearch} />
            )}
          </div>
        )}
        <video playsInline autoPlay muted loop>
          <source src="/img/video/braands-pink-bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="text-center">
          <Link to="/" aria-label="Read more about Seminole tax hike">
            <img
              width="230"
              height="51"
              alt=""
              style={{ width: "230px" }}
              src="/img/braands_logo.png"
            />
          </Link>
          <div className="container my-4 d-flex justify-content-center align-items-center">
            <div className="d-flex gap-4">
              <a
                rel="noreferrer"
                href="https://www.binance.com/en/nft/profile/braands-edbc125acb6d8801f0e84c6647682b2f?isBack=1"
                target="_blank"
                aria-label="binance-icon"
              >
                <SiBinance color="#fff" fontSize={24} />
              </a>
              <a
                rel="noreferrer"
                href="https://crypto.com/nft/profile/braands?tab=collected"
                target="_blank"
                aria-label="crypto-icon"
              >
                <img src="/img/icons/crypto.com-logo.svg" width="24px" />
              </a>
              <a
                rel="noreferrer"
                href="https://opensea.io/Braands"
                target="_blank"
                aria-label="opensea-icon"
              >
                <img src="/img/icons/opensea.io-logo.svg" width="24px" />
              </a>
              <a
                rel="noreferrer"
                href="https://rarible.com/braands"
                target="_blank"
                aria-label="linkedin-icon"
              >
                <svg fill="none" height="24" viewBox="0 0 49 48" width="25" xmlns="http://www.w3.org/2000/svg">
                  <path d="m.0966797 10.4931c0-5.79516 4.7127903-10.4931 10.5263203-10.4931h26.9474c5.8135 0 10.5263 4.69794 10.5263 10.4931v26.8625c0 5.7952-4.7128 10.4931-10.5263 10.4931h-26.9474c-5.81353 0-10.5263203-4.6979-10.5263203-10.4931z" fill="#FFF" />
                  <path clip-rule="evenodd" d="m36.1418 20.0308c0 2.2953-1.341 3.437-2.8481 3.8336 1.8038.5407 3.1092 2.0189 3.1092 4.3622v4.3022h-6.883v-4.0859c0-1.2498-.7358-1.7545-1.9937-1.7545h-8.1647v5.8404h-6.883v-17.2087h16.3531c4.106 0 7.3102.8892 7.3102 4.7107zm-16.7772.0972h9.305v.0008c.0139-.0005.0279-.0008.0419-.0008.6398 0 1.1585.5252 1.1585 1.1732 0 .6479-.5187 1.1731-1.1585 1.1731-.014 0-.028-.0002-.0419-.0007v.0007h-9.305z" fill="#F20D6B" fill-rule="evenodd" />
                </svg>
              </a>
              <a
                rel="noreferrer"
                href="https://app.axieinfinity.com/"
                target="_blank"
                aria-label="telegram-icon"
              >
                <img src="/img/icons/axie-infinity-axs-logo.svg" width="24px" />
              </a>
              <a
                rel="noreferrer"
                href="https://magiceden.io/me"
                target="_blank"
                aria-label="pinintrest-icon"
              >
                <img src="/img/icons/magiceden.io-logo.svg" width="24px" />
              </a>
            </div>
          </div>

          <p className="text-white mt-3">
            Unit of data stored on a digital ledger, called a blockchain, that
            certifies a digital asset.
          </p>
        </div>
        <div className="container my-4">
          <div
            className="menu text-white d-flex justify-content-center"
            style={{ gap: "40px" }}
          >
            <Link to={getURL("/")}>Home</Link>|<Link to={getURL("/about-us")}>About us</Link>|
            <Link to={getURL("/auction")}>Auction</Link>|<Link to={getURL("/trade")}>Trade</Link>|
            <a href="https://github.com/braands-io" target="_blank">
              Github
            </a>
            |<Link to={getURL("/contact")}>Contact Us</Link>
          </div>
        </div>
        <div className="container my-4">
          <div
            className="menu text-white d-flex justify-content-center"
            style={{ gap: "40px" }}
          >
            <a href={getURL("/careers")}>Career</a>|
            <a href="/files/TERMS-OF-USE.pdf" target="_blank">
              Terms of use
            </a>
            |
            <a href="/files/Privacy_Policy.pdf" target="_blank">
              Privacy Policy
            </a>
            |
            <a href="/files/AML_Policy.pdf" target="_blank">
              KYC/AML Policy
            </a>
            |
            <a href="https://medium.com/@Braands" target="_blank">
              News & Updates
            </a>
            |
            <a href={getURL("/faq")}>
              FAQ
            </a>
          </div>
        </div>
        <div className="text-center mt-3">
          <p className="text-white mb-2">Subscribe for Daily Newsletter</p>
          <div className="d-flex justify-content-center">
            <div className="search-footer">
              <input
                type="text"
                className="search-box"
                placeholder="Email ID"
                value={subsEmail}
                onChange={(e) => setSubsEmail(e.target.value)}
              />
              <button onClick={subscribeNow} className="search-button">
                Subscribe Now
              </button>
            </div>
          </div>
        </div>

        {/* Social Icons */}
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="col-md-6 mt-2">
              <div className="d-flex gap-4">
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/Braandsio"
                  target="_blank"
                  aria-label="facebook-icon"
                >
                  <AiFillFacebook color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://twitter.com/braandsio"
                  target="_blank"
                  aria-label="twitter-icon"
                >
                  <BsTwitter color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/braandsio"
                  target="_blank"
                  aria-label="instagram-icon"
                >
                  <BsInstagram color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/braandsio"
                  target="_blank"
                  aria-label="linkedin-icon"
                >
                  <BsLinkedin color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://t.me/braandsio"
                  target="_blank"
                  aria-label="telegram-icon"
                >
                  <FaTelegramPlane color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://in.pinterest.com/Braandsio"
                  target="_blank"
                  aria-label="pinintrest-icon"
                >
                  <BsPinterest color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.behance.net/Braands"
                  target="_blank"
                  aria-label="behance-icon"
                >
                  <FaBehanceSquare color="#fff" fontSize={24} />
                </a>
                <a
                  rel="noreferrer"
                  href="https://medium.com/@Braands"
                  target="_blank"
                  aria-label="si-icon"
                >
                  <SiMedium color="#fff" fontSize={24} />
                </a>
              </div>
            </div>
            <div className="col-md-6 mt-2 text-end">
              <p className="mb-0 text-white">
                &copy; 2023 All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div className="preloadImg">
        <div className="preloadImg__1"></div>
        <div className="preloadImg__2"></div>
        <div className="preloadImg__3"></div>
        <div className="preloadImg__4"></div>
        <div className="preloadImg__5"></div>
      </div>
    </>
  );
};

export default Footer1;
