import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import './assets/style_grey.scss';
import './assets/new_styles.scss';
import './assets/style-p.scss';
import TagManager from 'react-gtm-module'

import App from './components/app';
import * as serviceWorker from './serviceWorker';

//redux store
import { Provider } from 'react-redux'
import store from './store';

import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
// import { Web3Modal } from '@web3modal/react';
// import {chains} from "@web3modal/ethereum";
// import { Web3Button } from '@web3modal/react';

const getLibrary = (provider) => {
	return new Web3(provider);
}

const modalConfig = {
	theme: "dark",
	accentColor: "default",
	ethereum: {
		appName: "Braands",
		chains: [],
		providers: [
			
		]
	}
}

ReactDOM.render(
	<Provider store={store}>
		<Web3ReactProvider getLibrary={getLibrary}>
			{/* <Web3Modal config={modalConfig} > */}
				<App />
			{/* </Web3Modal> */}
		</Web3ReactProvider>
	</Provider>,
	document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();