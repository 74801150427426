import ABI from '../braands_abi.json';
import ABI1 from '../abi_braands_eth.json';
import ABI2 from '../abi_ens_minter.json'

export const APP_DATA = {
    API_URL: "https://braands.io/api",
    RPC_BNB: "https://bsc-dataseed1.binance.org/",
    RPC_ETH: "https://eth-goerli.g.alchemy.com/v2/p8aeUXE4peaBX1B5PqpxC0xXILahLA6w",
    RPC_MATIC: "https://polygon-rpc.com/",
    chainId1: '56',
    chainId2: '5',
    chainId3: '137',
    // contractAddress: "0x981f47eF245C2f2A6765295c966F5A3b7D1f123b",
    contractAddress: "0xe3a5284e979e1a60ed0abc1841070144057b989f",
    ABI : ABI,
    contractAddressENS: "0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5",
    ABIENS : ABI1,
    contractAddressENSMinter: "0x7d196accb6e695310f9bbbba465b1e655e6f8e01",
    ABIENSMinter : ABI2,
    contractAddressETH: "0x380dcDe4AD886D1227dD239f3a13Ad323C5848A6",
    contractAddressMATIC: "0xe3a5284e979e1a60ed0abc1841070144057b989f",
    resolverAddress: "0x42D63ae25990889E35F215bC95884039Ba354115",
    CURR_CURRENCY: localStorage.getItem("chainId")==='5' ? 'ETH':
                  (localStorage.getItem("chainId")=== '137' ? 'MATIC' :
                  'BNB')
}
