import { navigate } from "@reach/router";
import React, { memo, Suspense } from "react";
import { APP_DATA } from "../../core/utils";
import { priceToBNB, priceUpToDigit } from "../../utils";
import { ReactFitty } from "react-fitty";
const ImageComponent = React.lazy(() => import("../ImageComponent"));

const border2 = {
  border: `2px solid #F20D6B`,
};

const NftCard = ({ index, nft = null, type = null, className = "col-lg-3 col-md-4 col-sm-6 col-12 mt-2 mt-md-4" }) => {
  const onClickHandler = () => {
    if (nft.auctionId) {
      window.location.href = ("/auctionDetail/" + nft.auctionId);
    } else {
      window.location.href = ("/details/" + nft.itemId + "?ItemId");
    }
  }

  const colors = [
		{ bg: "light-blue-list-block", border: "blue-list-border" },
		{ bg: "light-orange-list-block", border: "orange-list-border" },
		{ bg: "light-lovender-list-block", border: "lovender-list-border" },
		{ bg: "light-yellow-list-block", border: "yellow-list-border" }
	];

  return (
    <div className={className}>
      <div className={"item-card cursor-pointer pink-list-border"} onClick={onClickHandler}>
        <div className="item-header-profile d-flex px-2">
          <Suspense fallback={<></>}>
            <ImageComponent path={"/img/profile-img.jpg"} className={"item-user"} aspectRatio={{ height: "50px", width: "50px" }} altTxt={"123"} />
          </Suspense>
          <span className="ms-2" style={{ width: "100%", paddingRight: "10px", marginTop: "13.5px", fontWeight: "700", fontSize: "20px", color: "#0d0c22" }}><ReactFitty maxSize="20">{nft?.userDetail?.username}</ReactFitty></span>
        </div>
        <div className="item-main text-center">
          <Suspense fallback={<div style={{ width: "228px", height: "228px" }}>Loading</div>}>
            <ImageComponent path={nft?.image ? nft.image : "/img/sample-logo.png"} className={"item-logo"} aspectRatio={{ height: "250", width: "250" }} altTxt={"123"} />
          </Suspense>
        </div>
        {/* <div className="back-logo h-100 position-absolute" style={{ top: "0", left: "0", transform: "translate3d(-20%, -10px, 0px) scale(0.8)", opacity: "0.05", zIndex: "0" }}>
          <img className="w-100 h-100" src={nft?.myIcon} alt="" />
        </div> */}
        <div className="item-footer border-top">
          <div className="item-title">
            <h2 className="mb-0" style={{ width: "100%", paddingRight: "15px" }}><ReactFitty minSize="1" maxSize="24">{nft?.title}</ReactFitty></h2>
          </div>
          {/* {type === "auction" && (
            <div className="auction-box">
              <span>Auction - <strong>8h : 20m</strong></span>
            </div>
          )} */}
          <div className="item-desc">
            {type === "auction" ?
              <span>
                <strong style={{ display: "inline-block", maxWidth: "calc(100% - 90px)" }}><ReactFitty minSize="1" maxSize="16">HB {priceUpToDigit(priceToBNB(nft?.hightestbid))} | SB {priceUpToDigit(priceToBNB(nft?.staringbid))} {APP_DATA.CURR_CURRENCY}</ReactFitty></strong> | Buy Now
              </span>
              :
              <span>
                <strong style={{ display: "inline-block", maxWidth: "calc(100% - 90px)" }}><ReactFitty minSize="1" maxSize="16">{priceUpToDigit(priceToBNB(nft?.totalprice))} {APP_DATA.CURR_CURRENCY}</ReactFitty> </strong> | Buy Now
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NftCard);
