import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { APP_DATA } from '../../core/utils';

const metaMaskConnector = new InjectedConnector({ supportedNetworks: [1, 4] });
const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    [APP_DATA.chainId1]: APP_DATA.RPC_BNB,
    [APP_DATA.chainId2]: APP_DATA.RPC_ETH,
    [APP_DATA.chainId3]: APP_DATA.RPC_MATIC,
  },
  qrcode: true,
  pollingInterval: 15000,
});

const ConnectViaWhhatsapp = (props) => {
  const sid = props.location.search.replace("?sid=", "");
  const [called, setCalled] = useState(false);
  const { activate, active, account } = useWeb3React();
  const [openPopup, setOpenPopup] = useState(true);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(true);

  const connectMetaMask = async () => {
    if (checkTermsOfUse) {
      try {
        if (window.ethereum) {
          await activate(metaMaskConnector);
        } else {
          toast.error("Metamask is not available on this browser!!!")
        }
      } catch (error) { }
    } else {
      toast.error("Please accept terms of use");
      return;
    }
  };

  const connectWallet = async () => {
    if (checkTermsOfUse) {
      try {
        await activate(walletConnectConnector);
        handleIsActive();
        const account = JSON.parse(localStorage.getItem("walletconnect"))?.accounts;
        if (account && account.length > 0) {
          toast.success("Signin Successful!");
        }
      } catch (error) { }
    } else {
      toast.error("Please accept terms of use");
      return;
    }
  };

  const handleIsActive = async () => {
    try {
      let metaMaskActive = await metaMaskConnector.isAuthorized();
      if (localStorage.getItem("walletDetails")) {
        if (metaMaskActive) {
          let walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
          if (walletDetails.address) {
            await activate(metaMaskConnector);
            if (!called) {
              setCalled(true);
              const response = await axios.post(APP_DATA.API_URL + "/whatsapp/connectwallet", { sid: sid, wallet_address: walletDetails.address });
              if (response?.data?.message) {
                window.location.href = "https://api.whatsapp.com/send/?phone=919922909794";
              }
            }
          }
        } else {
          localStorage.removeItem("walletDetails");
        }
      } else {
        if (localStorage.getItem("walletconnect")) {
          let walletDetails = JSON.parse(localStorage.getItem("walletconnect"));
          localStorage.setItem("chainId", walletDetails.chainId)
          await activate(walletConnectConnector);
          if (!called && walletDetails.accounts.length > 0) {
            setCalled(true);
            const response = await axios.post(APP_DATA.API_URL + "/whatsapp/connectwallet", { sid: sid, wallet_address: walletDetails.accounts[0] });
            if (response?.data?.message) {
              localStorage.setItem("walletDetails", walletDetails);
              window.location.href = "https://api.whatsapp.com/send/?phone=919922909794";
            }
          }
        } else if (active) {
          if (metaMaskActive) {
            let walletDetails = JSON.stringify({ address: account });
            if (!called) {
              setCalled(true);
              const response = await axios.post(APP_DATA.API_URL + "/whatsapp/connectwallet", { sid: sid, wallet_address: account });
              if (response?.data?.message) {
                localStorage.setItem("walletDetails", walletDetails);
                window.location.href = "https://api.whatsapp.com/send/?phone=919922909794";
              }
            }
          }
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (active) {
      handleIsActive();
    }
  }, [active]);


  return (
    <>
      <div>ConnectViaWhhatsapp</div>


      {openPopup && (
        <div className="checkout">
          <div className="maincheckout">
            <div className="heading mt-3 text-center">
              <h2 className="mb-0">Connect Wallet</h2>
              <p className="mb-4 text-black">Unique and Yours Forever</p>
            </div>

            <div className="row">
              <div className="col-lg-6 mb30 px-5">
                <span
                  className={`box-url text-center cursor-pointer ${checkTermsOfUse ? "" : "disabled"}`}
                  onClick={connectMetaMask}>
                  <span className="box-url-label" style={{ top: "5px", right: "5px", fontSize: "12px" }}>
                    Most Popular
                  </span>
                  <img src="./img/wallet/1.png" alt="" style={{ width: "70px", height: "70px" }} className="mb20 mt-2" />
                </span>
                <h4 className="text-center mt-2 mb-0">Metamask</h4>
              </div>
              <div
                className="col-lg-6 mb30 px-5"
                style={{ borderLeft: "1px solid rgba(0, 0, 0, .2)" }}
              >
                <span
                  className={`box-url text-center cursor-pointer ${checkTermsOfUse ? "" : "disabled"}`}
                  onClick={connectWallet}
                >
                  <img
                    src="./img/wallet/4.png"
                    style={{ width: "70px", height: "70px" }}
                    alt=""
                    className="mb20 mt-2"
                  />
                </span>
                <h4 className="text-center mt-2 mb-0">WalletConnect</h4>
              </div>
            </div>

            <div className="my-3 text-center checkTermsOfUseSection">
              <input id="checkTermsOfUse" type="checkbox" checked={checkTermsOfUse} onChange={() => setCheckTermsOfUse(prev => !prev)} />
              <label for="checkTermsOfUse" className="ms-2 text-black">By clicking, you agree to our <a href="/files/TERMS-OF-USE.pdf" target="_blank">Terms of use</a>, <br /><a href="/files/Privacy_Policy.pdf" target="_blank">Privacy Policy</a> and <a href="/files/Privacy_Policy.pdf" target="_blank">Cookies Policy</a>.
              </label>
            </div>
          </div>
        </div>
      )}
    </>

  )
}

export default ConnectViaWhhatsapp